import { useState, useEffect, useCallback, useMemo } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { setCurrentResultsElement } from "../../../../actions/UserActions";
import { getPageElements } from "../../../utils/PageElements";
import Dropdown from "../../../utils/Dropdown";
import moment from 'moment';
import {
	getNumericPredictionResults,
	getPollResults,
	getRegistrationResults,
	getScratchResults,
	getSoccerLineUpResults,
	getWheelOfPrizesResults
} from './ResultsCreator';
import { saveCSV } from "../../../utils/csv.mjs";
import { saveExcel } from "../../../utils/excel.mjs";
import { SwiperSlide } from 'swiper/react';
import HorizontalSlider from "../../../utils/HorizontalSlider";

function ResultsList() {
	const dispatch = useDispatch();
	const { resultsCurrentElement, unMappedUserResults, currentCampaign, currentCampaignId } = useSelector(state => state.user);
	const [sidebarItems, setSidebarItems] = useState([])
	const [forDownload, setForDownload] = useState([])

	const removeHtmlTagsAndSpecialChars = (str) => {
        str = str.replace(/<\/?[^>]+(>|$)/g, "");
        str = str.replace(/&nbsp;/g, " ");
        str = str.replace(/&amp;/g, "&");
        str = str.replace(/&lt;/g, "<");
        str = str.replace(/&gt;/g, ">");
        return str;
    }
	const mapQuestions = useCallback(() => {
		/* When other type of campaigns are added we have to take into consideration the campaign type in order to create the sidebar */
		let tableHeaderRegistration = [];
		let o = [];
		let registrationPlaceholders = {}
		currentCampaign.pages.forEach( page => {
			page.elements.forEach( element => {
				const defaultElement = getPageElements(element.type)
				let title = defaultElement?.text || 'Element';
				if(element.hasOwnProperty('header')){
					if (typeof element.header == 'object') title = removeHtmlTagsAndSpecialChars(element.header.html)
				}
				if (element.hasOwnProperty('question')) {
					title = removeHtmlTagsAndSpecialChars(element.question)
				}
				let tmp = {
					id: element.id,
					type: element.type,
					icon: defaultElement?.icon,
					title: title,
					tableHeader: [],
					rowPlaceholder: {},
					generalReportPlaceholder : {},
					answers: []
				};
				if(element.type === 'numericPrediction'){
					element.options.forEach( (option, idx) => {
						tmp.tableHeader.push({key: option.id, type: 'options', value: option.text ? option.text : 'Option '+ idx+1});
						tmp.rowPlaceholder[option.id] = null;
						tmp.generalReportPlaceholder[option.id] = [];

					})
					o.push(tmp)
				}
				if(element.type === 'registrationForm'){
					element.fields.forEach( field => {
						tableHeaderRegistration.push({key: field.id, type: `registration_${field.type}`, value: field.label});
						registrationPlaceholders[field.id] = null
					})
					o.push(tmp)
				}
				if (element.type === 'poll') {
					element.options.forEach((option, idx) => {
						//tmp.tableHeader.push({ key: option.id, type: 'options', value: option.text ? option.text : 'Option ' + idx + 1 });
						tmp.tableHeader = [{ key: option.id, type: 'options', value: 'Selected' }];
						tmp.rowPlaceholder[option.id] = null;
						tmp.generalReportPlaceholder[option.id] = {
							'text': option.text,
							'percentage': 0,
							'answers': []
						};
					})
					o.push(tmp)
				}
				if (element.type === 'multipleChoice') {
					element.options.forEach((option, idx) => {
						//tmp.tableHeader.push({ key: option.id, type: 'options', value: option.text ? option.text : 'Option ' + idx + 1 });
						tmp.tableHeader = [{ key: option.id, type: 'options', value: 'Selected' }];
						tmp.rowPlaceholder[option.id] = null;
						tmp.generalReportPlaceholder[option.id] = {
							'text': option.text,
							'percentage': 0,
							'answers': []
						};
					})
					o.push(tmp)
				}
				if (element.type === 'imageChoice') {
					element.options.forEach((option, idx) => {
						//tmp.tableHeader.push({ key: option.id, type: 'options', value: option.text ? option.text : 'Option ' + idx + 1 });
						tmp.tableHeader = [{ key: option.id, type: 'options', value: 'Selected'}];
						tmp.rowPlaceholder[option.id] = null;
						tmp.generalReportPlaceholder[option.id] = {
							'text': option.text,
							'percentage': 0,
							'image': option.image,
							'answers': []
						};
					})
					o.push(tmp)
				}
				if (element.type === 'mvp') {
					element.options.forEach((option, idx) => {
						//tmp.tableHeader.push({ key: option.id, type: 'options', value: option.text ? option.text : 'Option ' + idx + 1 });
						tmp.tableHeader = [{ key: option.id, type: 'options', value: 'Selected' }];
						tmp.rowPlaceholder[option.id] = null;
						tmp.generalReportPlaceholder[option.id] = {
							'text': option.text,
							'percentage': 0,
							'image': option.image,
							'answers': []
						};
					})
					o.push(tmp)
				}
				if (element.type === 'soccerLineupSelector') {
					tmp.generalReportPlaceholder = {
						formation: element.formations[0],
						categories: element.categories,
						players: element.players,
					};
					tmp.tableHeader.push({ key: 'asd', type: 'timeadded', value: 'Players' });
					tmp.rowPlaceholder['players'] = ""
					o.push(tmp)
				}
				if (element.type === 'wheelOfPrizes') {
					element.slices.forEach((slice) => {
						slice.prizes.forEach((prize) => {
							tmp.rowPlaceholder[prize.id] = 'No prize'
							tmp.generalReportPlaceholder[prize.id] = {...prize, prizeCount: 0};
						})
					})
					tmp.tableHeader.push({ key: 'prize', type: 'prize', value: 'Prize' })
					o.push(tmp)
				}
				if (element.type === 'scratch') {
					element.prizes.forEach((prize) => {
						tmp.rowPlaceholder[prize.id] = 'No prize'
						tmp.generalReportPlaceholder[prize.id] = {...prize, prizeCount: 0};
					})
					tmp.tableHeader.push({ key: 'prize', type: 'prize', value: 'Prize' })
					o.push(tmp)
				}
			})
		})
		o.forEach( item => {
			item.tableHeader = [...tableHeaderRegistration, ...item.tableHeader];
			item.tableHeader.push({ key: 'timeAdded', type:'timeadded', value: 'Time Added'});
			item.rowPlaceholder = {...registrationPlaceholders, ...item.rowPlaceholder, ...{timeAdded: null}}
		})
		if(o.length > 0) dispatch(setCurrentResultsElement(o[0]))
		setSidebarItems(o)
	}, [currentCampaign.pages, dispatch]);
	const getCss = (id) => {
		let o = 'tabs__item results__item';
		if(resultsCurrentElement){
			if(resultsCurrentElement.id === id) o += ' tabs__item-active';
		}
		return o;
	}
	const prepareDataForDownload = useCallback((elements) => {
		const endDate = currentCampaign.endDate ? moment(currentCampaign.endDate).format("YYYY-MM-DD HH:mm") : ''
		const d = elements.map(async (element) => {
			let d = []
			switch (element.type) {
				case 'numericPrediction':
					await getNumericPredictionResults(unMappedUserResults, element, 1)
						.then((calculatedResults) => {
							const c = calculatedResults[0].map((row) => {
								const r = row.map((value) => {
									return value
								});
								return [...[`${currentCampaign.name} - ${element.title} ${endDate}`], ...[currentCampaignId], ...r]
							})
							const header = [...['Campaign'], ...['Campaign Id'], ...element.tableHeader.map((hItem) => { return hItem.value; })]
							c.unshift(header)
							d.push(c)
						})
					break;
				case 'registrationForm':
					await getRegistrationResults(unMappedUserResults, element.rowPlaceholder)
						.then((calculatedResults) => {
							const c = calculatedResults.map((row) => {
								const r = row.map((value) => {
									return value
								});
								return [...[`${currentCampaign.name} - ${element.title} ${endDate}`], ...[currentCampaignId], ...r]
							})
							const header = [...['Campaign'], ...['Campaign Id'], ...element.tableHeader.map((hItem) => { return hItem.value; })]
							c.unshift(header)
							d.push(c)
						})
					break;
				case 'poll':
				case 'multipleChoice':
				case 'imageChoice':
				case 'mvp':
					await getPollResults(unMappedUserResults, element)
						.then( pollResults => {
							const [_, detailedAnswersByUser] = pollResults;
							const c = detailedAnswersByUser.map((row) => {
								const r = Object.values(row).map((value) => {
									return value
								});
								return [...[`${currentCampaign.name} - ${element.title} ${endDate}`], ...[currentCampaignId], ...r]
							})
							const header = [...['Campaign'], ...['Campaign Id'], ...element.tableHeader.map((hItem) => { return hItem.value; })]
							c.unshift(header)
							d.push(c)
						})
					break;
				case 'soccerLineupSelector':
					await getSoccerLineUpResults(unMappedUserResults, element)
						.then((calculatedResults) => {
							const rows = calculatedResults[3];
							if(!rows) return;
							const c = rows.map((r) => {
								return [...[`${currentCampaign.name} - ${element.title} ${endDate}`], ...[currentCampaignId], ...Object.values(r)]
							})
							const header = [...['Campaign'], ...['Campaign Id'], ...element.tableHeader.map((hItem) => { return hItem.value; })]
							c.unshift(header)
							d.push(c)
						})
					break;
				case 'wheelOfPrizes':
					await getWheelOfPrizesResults(unMappedUserResults, element, false)
						.then((calculatedResults) => {
							const c = calculatedResults[0].map((row) => {
								const r = row.map((value) => {
									if(value && typeof value === 'object'){
										return Array.isArray(value) ? value : value.text
									}
									return value
								});
								return [...[`${currentCampaign.name} - ${element.title} ${endDate}`], ...[currentCampaignId], ...r]
							})
							const header = [...['Campaign'], ...['Campaign Id'], ...element.tableHeader.map((hItem) => { return hItem.value; })]
							c.unshift(header)
							d.push(c)
						})
					break;
				case 'scratch':
					await getScratchResults(unMappedUserResults, element, false)
						.then((calculatedResults) => {
							const c = calculatedResults[0].map((row) => {
								const r = row.map((value) => {
									if(typeof value === 'object'){
										return Array.isArray(value) ? value : value.text
									}
									return value
								});
								return [...[`${currentCampaign.name} - ${element.title} ${endDate}`], ...[currentCampaignId], ...r]
							})
							const header = [...['Campaign'], ...['Campaign Id'], ...element.tableHeader.map((hItem) => { return hItem.value; })]
							c.unshift(header)
							d.push(c)
						})
					break;
				default:
					break;
			}
			return d
		})
		return d
	}, [currentCampaign, unMappedUserResults])
	const downloadCsv = useCallback(() => {
		const calculated = prepareDataForDownload(forDownload)
		Promise.all(calculated)
		.then( allCalculatedElements => {
			let d = []
			allCalculatedElements.forEach((element) => {
				element.forEach((row) => {
					d = [...d, ...row]
				})
			})
			saveCSV(d, currentCampaign.name)
		})
	}, [forDownload, currentCampaign.name, prepareDataForDownload])
	const downloadExcel = useCallback(() => {
		const calculated = prepareDataForDownload(forDownload)
		Promise.all(calculated)
		.then(allCalculatedElements => {
			let d = []
			allCalculatedElements.forEach((element) => {
				element.forEach((row) => {
					d = [...d, ...row]
				})
			})
			saveExcel(d, currentCampaign.name)
		})
	}, [forDownload])
	const isChecked = (id) => {
		return forDownload.find( element => element.id == id)
	}
	const setChecked = (element, event) => {
		if(event.target.checked){
			setForDownload([...forDownload, ...[element]])
		}else{
			setForDownload(forDownload.filter((el) => el.id != element.id))
		}
	}
	useEffect(() => {
		mapQuestions()
	}, [])
	return (
		<div>
			<div className="flex flex--row">
				<div className="float--right">
					<Dropdown isLeft={true}>
						<div className="dropdown-trigger btn btn-primary btn-inverted download__results mtop--5" disabled={forDownload.length === 0}>
							<span className="material-icons font--25 vm--align">
								download
							</span>
							Download ({forDownload.length})
						</div>
						<div className="dropdown__menu">
							<div className="dropdown__content">
								<div className="dropdown__item" onClick={downloadCsv}>CSV</div>
								<div className="dropdown__item" onClick={downloadExcel}>Excel</div>
							</div>
						</div>
					</Dropdown>
				</div>
			</div>
			<HorizontalSlider items={sidebarItems} css={'tabs mtop--10'} itemWidth={280}>
				{ sidebarItems.map( (item, index) => {
					return (
						<SwiperSlide className={getCss(item.id)} key={`sidebarItem__${index}`} onClick={() => {
							dispatch(setCurrentResultsElement(null))
							setTimeout(() => {
								dispatch(setCurrentResultsElement(item))	
							}, 100);
						}}>
							<div className="flex flex--row">
								<div className="material-icons font-25">{item.icon}</div>
								<span className="results__item-text">{item.title}</span>
								<div className="dropdown-trigger download__results mleft--10">
									<input type="checkbox" checked={!!isChecked(item.id)} onChange={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setChecked(item, e)
									}} />
								</div>
							</div>
						</SwiperSlide>
					)
				})}
			</HorizontalSlider>
		</div>
	);
}

export default ResultsList;
