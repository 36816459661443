import { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "../../../scss/pages/CampaignResults.scss";
import {getResponsesLive, getCampaign} from '../../../firebaseActions.mjs';
import {setCurrentCampaign, addUnMappedResult, deleteUnMappedResult} from "../../../actions/UserActions";
import ResultsList from "./elementResults/ResultsList";
import NumericPredictionResults from "./elementResults/NumericPredictionResults";
import RegistrationResults from "./elementResults/RegistrationResults";
import PollResults from "./elementResults/PollResults";
import ImageChoiceResults from "./elementResults/ImageChoiceResults";
import MvpResults from "./elementResults/MvpResults";
import SoccerLineupSelectorResults from './elementResults/SoccerLineupSelectorResults';
import WheelOfPrizesResults from './elementResults/WheelOfPrizesResults';
import ScratchResults from './elementResults/ScratchResults';

function CampaignResults(props) {
	const dispatch = useDispatch();
	const {currentCampaign, currentCampaignId, resultsCurrentElement} = useSelector(state => state.user);
	const [inited, setInited] = useState(false);
	const get = () => {
		getCampaign(currentCampaignId)
		.then( data => {
			dispatch(setCurrentCampaign({...data.campaign, name: data.campaignMeta.name}))
			getResponsesLive(currentCampaignId, (id, data) => {
				dispatch(addUnMappedResult({id, data}))
			}, (id, data) => {
				dispatch(addUnMappedResult({id, data}))
			}, (id) => {
				dispatch(deleteUnMappedResult({id}))
			})
		})
		.finally(() => setInited(true))
	}
	useEffect( () => {
		get();
	}, [])
	return (
		<>
			{inited &&
				<div>
					{ currentCampaign ?
						<div className="campaign__results">
							<div className="campaign__results-bar">
								<ResultsList />
							</div>
							<div className="campaign__results-content">
								{ resultsCurrentElement?.type === 'numericPrediction' && <NumericPredictionResults /> }
								{ resultsCurrentElement?.type === 'registrationForm' && <RegistrationResults />}
								{ resultsCurrentElement?.type === 'poll' && <PollResults />}
								{ resultsCurrentElement?.type === 'multipleChoice' && <PollResults />}
								{ resultsCurrentElement?.type === 'imageChoice' && <ImageChoiceResults />}
								{ resultsCurrentElement?.type === 'mvp' && <MvpResults />}
								{ resultsCurrentElement?.type === 'soccerLineupSelector' && <SoccerLineupSelectorResults />}
								{ resultsCurrentElement?.type === 'wheelOfPrizes' && <WheelOfPrizesResults />}
								{ resultsCurrentElement?.type === 'scratch' && <ScratchResults />}
							</div>
						</div>
						:
						""
					}
				</div>
			}
		</>
	);
}

export default CampaignResults;
