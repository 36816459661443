import { useState, useEffect } from "react";
import {useSelector} from 'react-redux';
import {getCampaign, setLiveControls, getLiveControls} from '../../../firebaseActions.mjs';
import ThemeSwitch from "../../utils/ThemeSwitch";

const validateNum = (val) =>{
	if(Number.isInteger(val) && val > 1) return true;
	if(typeof val === "string") return /^[0-9]+$/.test(val);
	return false;
}

const genPaused = (controlData) =>{
	return {...controlData, timedOut:true, lockTime:null};
}
function CampaignControl(){
	const {currentCampaignId} = useSelector(state => state.user);
	const [nextTimer, setNextTimer] = useState(15);
	const [tmpNextTimer, setTmpNextTimer] = useState(nextTimer);
	const [pages, setPages] = useState([]);
	const [controlData, setControlData] = useState(null);
	console.log(currentCampaignId)

	useEffect(()=>{
		getCampaign(currentCampaignId)
		.then( (data) => {
			console.log('data', data)
			setPages(data.campaign.pages)
		})
		.catch((e) => {
			alert('Could not load campaign.');
			console.error(e, { ...e });
			window.e = e
		})
		let unsub;
		if(currentCampaignId){
			unsub = getLiveControls(currentCampaignId, (controlData)=>{
				setControlData(controlData)
			});
		}
		return () => {
			if(unsub) unsub();
		}
	}, [currentCampaignId]);

	useEffect(() => {
		let timeoutId;
		if(controlData && controlData.lockTime && !controlData.timedOut){
			timeoutId = setTimeout(()=>setLiveControls(currentCampaignId, genPaused(controlData)), controlData.lockTime - Date.now());
		}
		return () => timeoutId&&clearTimeout(timeoutId);
	}, [controlData]);

	return (<div>
		<div>
			<div className="flex flex--row justify--spread">
				<span>
					<span>Timer:</span>
					<b>{nextTimer} seconds</b>
				</span>
				<ThemeSwitch />
			</div>
			<div>
				<input value={tmpNextTimer} onChange={(e) => setTmpNextTimer(e.target.value)} type="number" placeholder="Timer" style={{display: "inline-block",width: "100px"}}/>
				<button onClick={()=>{
					if(validateNum(tmpNextTimer)){
						setNextTimer(''+parseInt(tmpNextTimer, 10));
					}
				}} disabled={tmpNextTimer==nextTimer}>Use time</button>
			</div>
		</div>
		<div>
			<h3>{controlData?(controlData.timedOut?"Paused":<div>Running <button onClick={()=>setLiveControls(currentCampaignId, genPaused(controlData))}>Pause</button></div>):"?"}</h3>
		</div>
		<table>
			<tbody>
				{pages.map((page)=>{
					const isCurrent = page.id === controlData?.pageId;
					const isParticipatedPage = page.id === controlData?.participatedPageId;
					const isElapsedPage = page.id === controlData?.elapsedPageId;
					const isStartPage = page.id === controlData?.startPageId;
					const isEndPage = page.id === pages[pages.length-1]?.id;

					let textColor = null;
					if(isCurrent) textColor = "var(--primary)";
					if(isParticipatedPage) textColor = "var(--success)";
					if(isElapsedPage) textColor = "var(--danger)";
					const sE = page.elements.find((e)=>e.type==="multipleChoice");
					const sT = !sE && page.elements.find((e)=>e.type==="text");
					return <tr key={page.id}>
						<td style={{"width": "50px", "color":textColor}}>
							<span className="material-icons" style={{"fontSize":(isCurrent?"35px":"20px")}}>
								{sE&&"quiz"}
								{sT&&"html"}
							</span>
						</td>
						<td>
							<span dangerouslySetInnerHTML={{__html:sE?.question||sT?.html}}></span>
						</td>
						<td>
							{!isCurrent&&!isParticipatedPage&&!isElapsedPage&&<button onClick={()=>{
								const settings = {
									...controlData,
									timedOut:false,
									pageId:page.id,
									lockTime:Date.now()+(nextTimer*1000)
								};
								if(isStartPage||isEndPage){
									settings.timedOut = false;
									settings.lockTime = null;
								}
								return setLiveControls(currentCampaignId, settings);
							}}>Go!</button>}
							{!controlData?.participatedPageId&&<button onClick={()=>setLiveControls(currentCampaignId, {...controlData, participatedPageId:page.id})}>Set as participated</button>}
							{!controlData?.elapsedPageId&&<button onClick={()=>setLiveControls(currentCampaignId, {...controlData, elapsedPageId:page.id})}>Set as timed out page</button>}
							{!controlData?.startPageId&&<button onClick={()=>setLiveControls(currentCampaignId, {...controlData, startPageId:page.id})}>Set as start page</button>}
						</td>
					</tr>
				})}
			</tbody>
		</table>
	</div>);
}
export default CampaignControl;