import {useDispatch} from 'react-redux';
import ColorPallete from "../../../../utils/ColorPallete";
import EditableInput from "../../../../utils/EditableInput";
import ImageUpload from '../../../../utils/ImageUpload';
import { updateElement } from "../../../../utils/UpdateFunctions";
import { editElement } from "../../../../../actions/UserActions";
import RichText from "../../../../utils/RichText";

function WheelOfPrizesLook({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy }) {
	const dispatch = useDispatch();
	const { 
		buttonColor,
		textColor,
		marginTop,
		buttonText,
		nextButtonText,
		winMessage,
		lostMessage,
		borderImage,
		centerImage,
		centerImageSize,
		pinImage,
		pinImageSize,
		perpendicularText,
		borderStyle,
		borderColor,
		borderWidth,
		borderImageSize,
		winAnimationColors
	} = elementCopy;
	const spinDuration = Number.isFinite(elementCopy.spinDuration)?elementCopy.spinDuration:10;
	
	const update = (key, value, extra) => {
		if (key === 'marginTop') value += 'px';
		if (key === 'spinDuration') value = parseInt(value, 10);
		if (key === 'winAnimationColors'){
			const previous = elementCopy[key]?.slice() || [null, null, null]
			previous[extra] = value
			elementCopy[key] = previous
		}
		else {
			elementCopy[key] = value
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	console.log("winAnimationColors", winAnimationColors)
	return (
		<div>
			<div>
				<label className="text--bold">Element</label>
				<div className="campaign__option mtop--20">
					<label className="text--bold">Top Margin (pixels)</label>
					<div className="font--12 text--muted">The space the element has from the top</div>
					<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
				</div>
			</div>
			<div className="mtop--20">
				<label className="text--bold">Wheel</label>
				<div className="form__group">
					<div className="form__group-label">
						Spin duration
					</div>
					<div className="form__group-content">
						<EditableInput item="spinDuration" type="range" startValue={spinDuration} updateAction={update} placeholder={'Seconds'} min={1} max={10} step={1} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Button Text
					</div>
					<div className="form__group-content">
						<EditableInput item="buttonText" type="text" startValue={buttonText} updateAction={update} placeholder={'Spin'} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Next Button Text
					</div>
					<div className="form__group-content">
						<EditableInput item="nextButtonText" type="text" startValue={nextButtonText} updateAction={update} placeholder={'Next'} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Button Color
					</div>
					<div className="form__group-content">
						<ColorPallete item="buttonColor" value={buttonColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Text Color
					</div>
					<div className="form__group-content">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Text Orientation
					</div>
					<div className="form__group-content">
						<div className="form__group-check">
							<div className={`${!perpendicularText && 'form__group-checkActive'} font--12`} onClick={() => update('perpendicularText', false)}>
								Parallel
							</div>
							<div className={`${perpendicularText && 'form__group-checkActive'} font--12`} onClick={() => update('perpendicularText', true)}>
								Perpendicular
							</div>
						</div>
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Border Style
					</div>
					<div className="form__group-content">
						<div className="form__group-check">
							<div className={`${ (!borderStyle) || borderStyle === "stroked" && 'form__group-checkActive'} font--12`} onClick={() => update('borderStyle', "stroked")}>
								Stroked
							</div>
							<div className={`${borderStyle === "fanned" && 'form__group-checkActive'} font--12`} onClick={() => update('borderStyle', "fanned")}>
								Fanned
							</div>
						</div>
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Border Color
					</div>
					<div className="form__group-content">
						<ColorPallete item="borderColor" value={borderColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Border Width
					</div>
					<div className="form__group-content">
						<EditableInput item="borderWidth" type="number" startValue={borderWidth} updateAction={update} placeholder="5" />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Win Animation Colors
					</div>
					<div className="flex justify-start gap-4" style={{ flexBasis: "30%"}}>
						<ColorPallete item="winAnimationColors" value={winAnimationColors?.[0]} type={'hex'} updateAction={update} extra={0} isLeft={true} />
						<ColorPallete item="winAnimationColors" value={winAnimationColors?.[1]} type={'hex'} updateAction={update} extra={1} isLeft={true} />
						<ColorPallete item="winAnimationColors" value={winAnimationColors?.[2]} type={'hex'} updateAction={update} extra={2} isLeft={true} />
					</div>
				</div>
				<div className="flex flex--row flex--wrap mtop--20">
					<div className="campaign__option campaign__option-50">
						<label className="text--bold">Border Image</label>
						<div className="text--muted font--12">An image placed on the radius of the wheel. Add your custom border image to enhance the wheel design.</div>
						<div className="campaign__option-act">
							<ImageUpload dimensions={'1500x1500 px'} campaign_id={currentCampaignId} file={borderImage} item="borderImage" updateAction={update} />
						</div>
						<div className="form__group mtop--20">
							<div className="form__group-label">
								Image Size (%)
								<div className="font--12 text--muted">Adjust accordingly. (Can go above 100%)</div>
							</div>
							<div className="form__group-content">
								<EditableInput item="borderImageSize" type="number" startValue={borderImageSize} updateAction={update} placeholder={'Type here'} />
							</div>
						</div>
					</div>
					<div className="campaign__option campaign__option-50 mleft--5">
						<label className="text--bold">Center Image</label>
						<div className="text--muted font--12">Add your custom image inside the wheel's center.</div>
						<div className="campaign__option-act">
							<ImageUpload dimensions={'150x150 px'} campaign_id={currentCampaignId} file={centerImage} item="centerImage" updateAction={update} />
						</div>
						<div className="form__group mtop--20">
							<div className="form__group-label">
								Center Image Size (%)
								<div className="font--12 text--muted">Percent from the whole wheel.</div>
							</div>
							<div className="form__group-content">
								<EditableInput item="centerImageSize" type="number" startValue={centerImageSize} updateAction={update} placeholder={'Type here'} />
							</div>
						</div>
					</div>
					<div className="campaign__option campaign__option-50 mleft--5">
						<label className="text--bold">Pin Image</label>
						<div className="text--muted font--12">Add your custom pin image.</div>
						<div className="campaign__option-act">
							<ImageUpload dimensions={'150x150 px'} campaign_id={currentCampaignId} file={pinImage} item="pinImage" updateAction={update} />
						</div>
						<div className="form__group mtop--20">
							<div className="form__group-label">
								Pin Image Size (%)
								<div className="font--12 text--muted">Percent size (Can go above 100%).</div>
							</div>
							<div className="form__group-content">
								<EditableInput item="pinImageSize" type="number" startValue={pinImageSize} updateAction={update} placeholder={'Type here'} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mtop--20">
				<label className="text--bold">Win message</label>
				<RichText item={'winMessage'} returnEmptyString={true} value={winMessage} updateAction={update} isSmall={true} />
			</div>
			<div className="mtop--20">
				<label className="text--bold">Lost message</label>
				<RichText item={'lostMessage'} returnEmptyString={true} value={lostMessage} updateAction={update} isSmall={true} />
			</div>
		</div>
	);
}

export default WheelOfPrizesLook;
