import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {addCampaignPage, deleteCampaignPage, moveCampaignPage} from '../../../firebaseActions.mjs';
import {setCurrentPage,setCampaignPages, removePageStore, reorderCampaignPagesStore, setCurrentElement} from "../../../actions/UserActions";
import {PAGE} from "./CampaignAddDefaults";
import DeletePageModal from "./navigation/DeletePageModal.jsx";
import {Notification, ErrorNotification} from '../../utils/Misc';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {genUUID} from '../../utils/Misc';
import Page from './navigation/Page';

function PageSelector() {
	const dispatch = useDispatch();
	const [dragLoader, setDragLoader] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [adding, setAdding] = useState(false);
	const {currentCampaign, currentPage, currentCampaignId} = useSelector(state => state.user);

	const getPageCss = (page_id, index) => {
		let s = "campaign__edit-page";
		if( currentPage && (page_id === currentPage.id )) s += " campaign__edit-pageActive";
		if(index  === currentCampaign.pages.length-1) s += " campaign__edit-pageEnd";
		return s;
	}
	const addPage = (page) => {
		setAdding(true);
		const p = page ? page : PAGE();
		addCampaignPage(currentCampaignId, 'pages', p.id, currentCampaign.pages.length-1, p)
		.then( data => {
			Notification('Page Added', 'success');
			data.pages = data.pages.map((p, idx) => {
				const t = idx === data.pages.length-1 ? 'endPage' : 'normal';
				return {...p, ...{type: t}}
			})
			dispatch( setCampaignPages(data.pages) );
		})
		.catch( e => {
			ErrorNotification('Could not add page.', e, {});
			console.error(e, {...e});
		})
		.finally( () => setTimeout(() => {
			setAdding(false);
		}, 500))
	}
	const deletePage = page => {
		setDeleteModal(null);
		deleteCampaignPage(currentCampaignId, 'pages', page.id)
		.then( data => {
			let i = 0;
			currentCampaign.pages.forEach((pg, index) => {
				if(pg.id === page.id) i = index;
			});
			if(i > 0){
				dispatch(setCurrentPage(currentCampaign.pages[i-1]))
			}else if( (i === 0) && (currentCampaign.pages.length > 1) ) {
				dispatch(setCurrentPage(currentCampaign.pages[i+1]))
			}else{
				dispatch(setCurrentPage(currentCampaign.pages[0]))
			}
			Notification('Page Deleted');
			dispatch(removePageStore(page.id));
		})
		.catch( e => {
			ErrorNotification('Could not delete page.', e, {});
			console.error(e, {...e});
		})
	}
	const replaceIds = (obj) => {
		return new Promise((resolve) => {
			(function traverse(currentObject) {
				for (const key in currentObject) {
					if (currentObject.hasOwnProperty(key)) {
						if (key === 'id') {
							currentObject[key] = genUUID();
						} else if (typeof currentObject[key] === 'object') {
							if (Array.isArray(currentObject[key])) {
								const promises = currentObject[key].map(element => traverse(element));
								Promise.all(promises).then(() => resolve());
							} else {
								traverse(currentObject[key]);
							}
						}
					}
				}
			})(obj);
			resolve();
		});
	}
	const duplicatePage = async page => {
		const {type, ...dt} = page;
		await replaceIds(dt);
		addPage(dt);
	}
	const goTo = (p, type) => {
		if(type !== 'normal') p['type'] = type
		dispatch( setCurrentElement(null) )
		dispatch( setCurrentPage(p) )
	}
	const SortableItem = SortableElement(({page, i}) => <Page 
		page={page}
		i={i}
		goTo={goTo}
		duplicatePage={duplicatePage}
		currentCampaign={currentCampaign}
		getPageCss={getPageCss}
		setDeleteModal={setDeleteModal}
	/>);
	const SortableList = SortableContainer(({items}) => {
		return (
			<ul className="sortable__container">
				{
					items.map((value, index) => (
						<SortableItem key={`sortable-${index}`} i={index} index={index} page={value} />
					))
				}
			</ul>
		);
	});
	const onSortEnd = (data) => {
		setDragLoader(true);
		const page_id = currentCampaign.pages[data.oldIndex].id;
		moveCampaignPage(currentCampaignId, 'pages', page_id, data.newIndex)
		.then((data) => {
			data.pages = data.pages.map((p, idx) => {
				const t = idx === data.pages.length-1 ? 'endPage' : 'normal';
				return {...p, ...{type: t}}
			})
			dispatch( reorderCampaignPagesStore(data.pages));
		})
		.catch( e => {
			ErrorNotification('Could not move page.', e, {});
			console.error(e, {...e});
		})
		.finally(() => setDragLoader(false))
	}
	return (
		<>
			{  dragLoader && <div className="page__selector-loader"><span className="loader"></span></div> }
			<button className="btn btn-primary btn-100" onClick={() => addPage()} disabled={adding}>
				{adding && <span className="loader loader__sm"></span>}
				{!adding &&
					<>
						<span className="material-icons font--20 vm--align">
							add
						</span>
						Add Page
					</>
				}
			</button>
			<div className="campaign__pages-container">
				{ !dragLoader && <SortableList axis="y" useDragHandle items={currentCampaign.pages} onSortEnd={onSortEnd} /> }
				{ currentCampaign['endDate'] &&
					<div className={`${getPageCss(currentCampaign?.expiredPage['id'], currentCampaign.pages.length)} campaign__edit-pageDanger`} onClick={() => goTo(currentCampaign?.expiredPage, 'expiredPage')}>
						<div className="campaign__edit-pageText text--danger">Expired</div>
						<div className="text--muted font--10">This page only appears when the campaign has expired</div>
					</div>
				}

			</div>
			{ deleteModal && <DeletePageModal deleteAction={deletePage} closeCb={setDeleteModal} data={deleteModal} /> }
		</>
	);
}

export default PageSelector;
